import { FC } from 'react';
import IconProps from './IconProps';

const NewsAndUpdatesIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  if (gradient) {
    return (
      <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.0489 19.7161H21.2655C23.5022 19.7161 25.3155 17.9029 25.3155 15.6661V6.04998C25.3155 3.81324 23.5022 2 21.2655 2H5.36578C3.12904 2 1.3158 3.81324 1.3158 6.04999V6.9163"
          stroke="url(#paint0_linear_4183_66127)"
          strokeWidth="2.31428"
          strokeLinecap="round"
        />
        <path
          d="M11.7158 20.5161C11.7158 14.7724 7.0596 10.1162 1.31592 10.1162"
          stroke="url(#paint1_linear_4183_66127)"
          strokeWidth="1.73571"
          strokeLinecap="round"
        />
        <path
          d="M8.74439 20.5161C8.74439 16.4135 5.41855 13.0876 1.31592 13.0876"
          stroke="url(#paint2_linear_4183_66127)"
          strokeWidth="1.73571"
          strokeLinecap="round"
        />
        <path
          d="M5.773 20.5162C5.773 18.0546 3.7775 16.0591 1.31592 16.0591"
          stroke="url(#paint3_linear_4183_66127)"
          strokeWidth="1.73571"
          strokeLinecap="round"
        />
        <defs>
          <linearGradient id="paint0_linear_4183_66127" x1="1.3158" y1="7.4095" x2="22.5726" y2="19.7193" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_4183_66127" x1="1.31592" y1="13.2917" x2="11.7158" y2="17.7375" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint2_linear_4183_66127" x1="1.31592" y1="15.3559" x2="8.74439" y2="18.5314" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint3_linear_4183_66127" x1="1.31592" y1="17.42" x2="5.773" y2="19.3253" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7332 19.7161H21.9497C24.1864 19.7161 25.9997 17.9029 25.9997 15.6661V6.04998C25.9997 3.81324 24.1864 2 21.9497 2H6.04998C3.81324 2 2 3.81324 2 6.04999V6.9163"
        stroke="currentColor"
        strokeWidth="2.31428"
        strokeLinecap="round"
      />
      <path d="M12.3999 20.5161C12.3999 14.7724 7.74368 10.1163 2 10.1163" stroke={accentColor} strokeWidth="1.73571" strokeLinecap="round" />
      <path d="M9.42847 20.5161C9.42847 16.4135 6.10263 13.0876 2 13.0876" stroke={accentColor} strokeWidth="1.73571" strokeLinecap="round" />
      <path d="M6.45708 20.5161C6.45708 18.0545 4.46158 16.059 2 16.059" stroke={accentColor} strokeWidth="1.73571" strokeLinecap="round" />
    </svg>
  );
};

export default NewsAndUpdatesIcon;
